@font-face {
  font-family: 'Nunito';
  src: url('./Nunito-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoBold';
  src: url('./Nunito-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PoppinsBold';
  src: url('./Poppins-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PoppinsMedium';
  src: url('./Poppins-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
  
@font-face {
  font-family: 'Grotesk';
  src: url('./SpaceGrotesk-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GroteskSemi';
  src: url('./SpaceGrotesk-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TiltWarp';
  src: url('./TiltWarp-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TiltWarp2';
  src: url('./TiltWarp2.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
