.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: hsl(104, 60%, 8%);
  background-color: #f6faf5; 
    /* H:105 S:2 B:98 */

}

.section-header {
  color: #ff3d6e; 
    /* H:345 S:76 B:100 */
  font-weight: bold;
  font-family: 'Rammetto One', cursive;
}

.header-normal {
  font-weight: normal;
}

.section-text{
  color: hsl(104, 60%, 5%);
    /* H:104 S:75 B:8 */
  font-family: 'Nunito', sans-serif;
}

.modal-button {
  color: rgba(108, 122, 137, 0.8);
}

.wrapper {
  overflow-x: hidden;
  position: relative;
}



